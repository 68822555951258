import { Controller } from '@hotwired/stimulus'

const PERIOD = 10_000

// Connects to data-controller="apology"
export default class extends Controller {
  static get targets() {
    return ['content']
  }

  connect() {
    this.options = JSON.parse(this.element.dataset.apologies)
    this.lastTimeout = setTimeout(() => {
      this.showApology(0)
    }, PERIOD)
  }

  showApology(index) {
    this.contentTarget.innerHTML = this.options[index]
    if (index + 1 < this.options.length) {
      setTimeout(() => {
        this.showApology(index + 1)
      }, (index + 1) * PERIOD)
    }
  }

  disconnect() {
    clearTimeout(this.lastTimeout)
  }
}
