import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="auto-remove"
export default class extends Controller {
  remove() {
    setTimeout(() => {
      this.element.remove()
    }, 100)
  }
}
