import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash.debounce'

// Connects to data-controller="form"
export default class extends Controller {
  initialize() {
    this.submit = debounce(this.submit.bind(this), 200)
  }

  connect() {
    this.submitTarget.hidden = true
  }

  static get targets() {
    return ['submit']
  }

  submit() {
    this.submitTarget.click()
  }
}
