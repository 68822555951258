import { Controller } from '@hotwired/stimulus'

import tinymce from 'tinymce'

import 'tinymce/themes/silver'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.min.css'

import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'

import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.min.css'

// Connects to data-controller="editor"
export default class extends Controller {
  static get targets() {
    return ['input']
  }

  initialize() {
    this.defaults = {
      promotion: false,
      branding: false,
      skin: false,
      content_css: false,
      menubar: false,
      statusbar: false,
      min_height: 400,
      autoresize_bottom_margin: 10,
      link_context_toolbar: true,
      link_default_target: '_blank',
      link_title: false,
      max_height: 700,
      plugins: ['advlist', 'lists', 'link', 'autoresize'],
      relative_urls: false,
      toolbar: [
        'bold italic | h1 h2 h3 h4 | blockquote numlist bullist link outdent indent | ' +
        'alignleft aligncenter alignright alignjustify | undo redo removeformat'
      ],
      content_style: 'body { font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif; font-size: 14px; -webkit-font-smoothing: antialiased; }'
    }
  }

  connect() {
    if (!this.preview) {
      const config = Object.assign({ target: this.inputTarget }, this.defaults)
      tinymce.init(config)
    }
  }

  disconnect() {
    if (!this.preview) tinymce.remove()
  }

  get preview() {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }
}
