import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

// Connects to data-controller="tomselect"
export default class extends Controller {
  static get targets() {
    return ['select']
  }

  connect() {
    // eslint-disable-next-line no-new
    new TomSelect(this.selectTarget, { maxOptions: null })
  }

  disconnect() {
    this.selectTarget.tomselect.destroy()
  }
}
