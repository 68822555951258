import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="check-all"
export default class extends Controller {
  connect() {}

  static get targets() {
    return ['checkAll', 'checkNone', 'checkbox']
  }

  checkAll(event) {
    this.setChecked(true, event)
  }

  checkNone(event) {
    this.setChecked(false, event)
  }

  setChecked(checked, event) {
    event.preventDefault()
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })
    this.change()
  }

  change() {
    let allChecked = true
    this.checkboxTargets.forEach((checkbox) => {
      allChecked = allChecked && checkbox.checked
    })
    if (allChecked) {
      this.checkAllTarget.classList.add('d-none')
      this.checkNoneTarget.classList.remove('d-none')
    } else {
      this.checkAllTarget.classList.remove('d-none')
      this.checkNoneTarget.classList.add('d-none')
    }
  }
}
