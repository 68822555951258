import { Controller } from '@hotwired/stimulus'
import { saveAs } from 'file-saver'

import bsConfirm from '../src/bs-confirm'

// Connects to data-controller="confirmed-download"
export default class extends Controller {
  async click(event) {
    event.preventDefault()
    const confirmText = this.element.dataset.confirm
    const result = await bsConfirm(confirmText)
    if (result) {
      saveAs(this.element.dataset.url)
    }
  }
}
