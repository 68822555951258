import { Turbo } from '@hotwired/turbo-rails'
import '@popperjs/core'
import { Tooltip, Popover } from 'bootstrap'
import 'chartkick/chart.js'

import 'stylesheets/application.scss'
import './controllers'
import initCopyClips from './src/copy-clip'
import bsConfirm from './src/bs-confirm'
import { initChat } from './src/chat'

require.context('./images', true)

Turbo.config.forms.confirm = bsConfirm

const initTooltips = function() {
  [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function(el) {
    return Tooltip.getOrCreateInstance(el)
  });
  [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]')).map(function(el) {
    return Popover.getOrCreateInstance(el)
  })
}

const initComponents = function() {
  initTooltips()
  initCopyClips()
}

document.addEventListener('turbo:load', initComponents)
document.addEventListener('turbo:load', initChat)
document.addEventListener('turbo:frame-load', initComponents)
