import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="auto-show"
export default class extends Controller {
  connect() {
    this.change()
  }

  static get targets() {
    return ['toggle', 'hiding']
  }

  change() {
    let val = null
    this.toggleTargets.forEach((radio) => {
      if (radio.checked) {
        val = radio.value
      }
    })
    const hideOnValue = this.hidingTarget.dataset.hideOnValue
    this.hidingTarget.hidden = val === hideOnValue
  }
}
