import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="labeled-range"
export default class extends Controller {
  connect() {
    this.change()
  }

  static get targets() {
    return ['slider', 'output']
  }

  change() {
    const val = this.sliderTarget.value
    if (val < 1) {
      this.outputTarget.textContent = this.sliderTarget.dataset.labelLow
    } else if (val < 5) {
      this.outputTarget.textContent = this.sliderTarget.dataset.labelMedLow
    } else if (val < 10) {
      this.outputTarget.textContent = this.sliderTarget.dataset.labelMedHigh
    } else {
      this.outputTarget.textContent = this.sliderTarget.dataset.labelHigh
    }
  }
}
