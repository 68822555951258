import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="show-hide-link"
// a controller that gets an element as target that is hidden by default
// it can have any number of links that will show/hide the target
export default class extends Controller {
  connect() {
  }

  static get targets() {
    return ['target']
  }

  toggle(event) {
    event.preventDefault()
    this.targetTargets.forEach((target) => {
      target.hidden = !target.hidden
    })
  }
}
