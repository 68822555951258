import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="writing-new-form"
export default class extends Controller {
  static get targets() {
    return ['output', 'button']
  }

  connect() {
  }

  change(event) {
    this.outputTarget.value = event.target.value
    this.buttonTarget.disabled = false
  }
}
