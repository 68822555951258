import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /**
   * Updates the purchase detail frame source based on the input value after a debounce period.
   *
   * @param {Event} event - The input event triggered by the user.
   */
  updateDetail(event) {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      document.getElementById('updateDetailButton').click()
    }, 200)
  }
}
