import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="failed-payment-mode-switch"
export default class extends Controller {
  static get targets() {
    return ['radio', 'card', 'bank']
  }

  connect() {
    this.change()
  }

  change() {
    const val = this.radioTargets.find((e) => e.checked).value
    let toHide = this.cardTargets
    let toShow = this.bankTargets
    if (val === 'failed_card') {
      toHide = this.bankTargets
      toShow = this.cardTargets
    }
    toHide.forEach((e) => {
      e.classList.add('d-none')
    })
    toShow.forEach((e) => {
      e.classList.remove('d-none')
    })
  }
}
