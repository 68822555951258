import { Modal } from 'bootstrap'

export default function(message, _element, _submitter) {
  const element = document.getElementById('turbo_confirm')
  element.querySelector('.modal-body').textContent = message
  const modal = Modal.getOrCreateInstance(element)
  modal.show()

  return new Promise((resolve, _reject) => {
    let resolved = false
    element.addEventListener('hidden.bs.modal', () => {
      if (!resolved) resolve(false)
    }, { once: true })
    element.querySelector("button[value='yes']").addEventListener('click', () => {
      resolve(true)
      resolved = true
    }, { once: true })
    element.querySelector("button[value='no']").addEventListener('click', () => {
      resolve(false)
      resolved = true
    }, { once: true })
  })
}
