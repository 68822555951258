import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="planned-change-field"
export default class extends Controller {
  static get targets() {
    return ['select']
  }

  connect() {
    this.change()
  }

  change() {
    const val = this.selectTarget.value
    this.element.querySelectorAll('.field_value').forEach((el) => {
      el.classList.add('d-none')
    })
    this.element.querySelectorAll('.field_value_input, .field_value input, .field_value select').forEach((el) => {
      el.disabled = true
    })
    this.element.querySelectorAll(`.field_value_${val}`).forEach((el) => {
      el.classList.remove('d-none')
    })
    this.element.querySelectorAll(`.field_value_${val} .field_value_input, .field_value_${val} input`).forEach((el) => {
      el.disabled = false
    })
  }
}
