import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="copilot-tenant-input"
export default class extends Controller {
  static get targets() {
    return ['optimcall', 'jarabot']
  }

  connect() {
    this.toggle()
  }

  toggle() {
    const selectedType = document.querySelector('input[name="voice_copilot_setting[pbx_type]"]:checked').value
    this.toggleField(this.optimcallTargets, selectedType === 'optimcall')
    this.toggleField(this.jarabotTargets, selectedType === 'jarabot')
  }

  toggleField(targets, isVisible) {
    targets.forEach(e => {
      e.style.display = isVisible ? 'block' : 'none'
      e.querySelectorAll('input, select').forEach(input => {
        input.disabled = !isVisible
      })
    })
  }
}
