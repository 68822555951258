import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="dynamic-text-fields"
export default class extends Controller {
  static get targets() {
    return ['input']
  }

  addInputIfNeeded() {
    const lastInput = this.inputTargets[this.inputTargets.length - 1]
    if (lastInput.value.trim() !== '') {
      this.addInput(lastInput)
    }
  }

  addInput(lastInput) {
    const newInput = lastInput.cloneNode(true)
    newInput.value = ''
    newInput.classList.add('mt-1')
    lastInput.parentNode.insertBefore(newInput, lastInput.nextSibling)
  }
}
