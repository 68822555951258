import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="planned-change-products"
export default class extends Controller {
  connect() {
    this.paramPrefix = this.element.dataset.paramName || 'elements_planned_change'
  }

  static get targets() {
    return ['inputs', 'textField']
  }

  change(event) {
    console.debug(`Checkbox ${event.target.value} changed to ${event.target.checked}`)
    if (event.target.checked) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = `${this.paramPrefix}[code][]`
      input.value = event.target.value
      input.dataset.product = event.target.dataset.product
      this.inputsTarget.appendChild(input)
    } else {
      this.inputsTarget.querySelectorAll(`input[value="${event.target.value}"]`).forEach((input) => {
        input.remove()
      })
    }
    this.textFieldTarget.value = Array.prototype.map.call(
      this.inputsTarget.querySelectorAll('input'),
      (input) => input.dataset.product
    ).join(', ')
  }
}
