const updateTooltip = (el) => {
  const ttEl = document.getElementById(el.getAttribute('aria-describedby'))
  if (ttEl && el.dataset.copyDoneText) {
    const ttInner = ttEl.querySelector('.tooltip-inner')
    if (ttInner) {
      const oldHtml = ttInner.innerHTML
      ttInner.innerHTML = el.dataset.copyDoneText
      setTimeout(function() {
        ttInner.innerHTML = oldHtml
        el.blur()
      }, 1000)
    }
  }
}

const initCopyClips = () => {
  document.querySelectorAll('[data-copy-clip-value]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      navigator.clipboard.writeText(el.dataset.copyClipValue)
      updateTooltip(el)
    })
  })
}

export default initCopyClips
